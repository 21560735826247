import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import StylePropType from 'react-style-proptype';
/**
 * Standard image component
 */
export default class Image extends PureComponent {

  static propTypes = {
    /** Additional className to apply to the image */
    className: PropTypes.string,
    /** Src of the image */
    src: PropTypes.string.isRequired,
    /** Centers the image in its parent container */
    center: PropTypes.bool,
    /** Metadata title for the image */
    title: PropTypes.string,
    /** Used for JSONPageTemplate - set to true if you dont need to 'require()' the image */
    lazy: PropTypes.bool,
    /** Alt metadata for the image */
    alt: PropTypes.string,
    /** Additional style to apply to the image */
    style: StylePropType
  }

  render() {
    let { className = '', src = '', center = false, title='', lazy, alt = '' } = this.props;
    const style = center ? { ...this.props.style, marginLeft: '50%', transform: 'translateX(-50%)' } : this.props.style;

    if (lazy && !src.startsWith('/') && !src.startsWith('http')) {
      src = '/' + src;
    }

    return <img title={title} className={`OptimizedImage ${className}`} src={src} style={style} alt={alt} /> 
    
  }
}