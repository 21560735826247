import ContactEmails from '../../constants/contact-emails.json';

import { getPathInfo } from '../../services/URL';
import EmailBuilder from '../../services/EmailBuilder';

export default (pathname, body, email) => {
  const to = ContactEmails.feedbackForm.receivers[platform] || 'support@apryse.com';
  const { platform } = getPathInfo(pathname);

  return new EmailBuilder()
    .setTo(to)
    .setSubject(`Anonymous feedback on article ${pathname}`)
    .setSubheader({
      text: "New guides feedback has been submitted"
    })
    .addJSON({
      data: {
        'Link to the article': `${window.location.origin}${pathname}`,
        'Feedback': body,
        'Email': email
      }
    })
}