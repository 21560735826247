import React, { PureComponent } from 'react';

import Link from '../Link';
import { getFormattedNavLink, getNavItems } from '../../services/DocumentationService';

import './FooterNavLinks.scss';

export default class FooterNavLinks extends PureComponent {


  render() {
    const { platform } = this.props;
    const { nextNavItem: nextNav, previousNavItem: previousNav } = getNavItems();
    return (
      <div className="FooterNavLinks">
          <div className='article-navs'>
            <div className='previous'>
              {previousNav && previousNav.link &&
                <Link to={getFormattedNavLink(previousNav[platform] || previousNav.link, platform).link}>
                  <div>←</div>
                  <div>{previousNav.title}</div>
                </Link>
              }
            </div>
            <div className='next'>
              {nextNav && nextNav.link &&
                <Link to={getFormattedNavLink(nextNav[platform] || nextNav.link, platform).link}>
                  <div>{nextNav.title}</div>
                  <div>→</div>
                </Link>
              }
            </div>
          </div>
      </div>  
    );
  }
}