import React, { PureComponent } from 'react';

import Button from '../Button';
import FeedbackEmailTemplate from '../../templates/EmailTemplates/FeedbackEmailTemplate';
import './Feedback.scss';
import './Feedback.mobile.scss';
export default class Feedback extends PureComponent {
  constructor() {
    super();
    this.state = {
      askForFeedback: false,
      done: false,
    };
  }

  onBooleanClick = (positive) => {    
    if (positive === true) {
      this.setState({
        done: true
      });  
    } else {
      this.setState({
        askForFeedback: true
      });
    }
  }

  onClick = () => {
    if (this.textRef.value.trim().length === 0) {
      return;
    }

    const email = this.emailref.value.trim();
    
    FeedbackEmailTemplate(this.props.pathname, this.textRef.value, email)
      .send();

    this.setState({ done: true, askForFeedback: false });
  }

  render() {
    return (
      <div className='Feedback'>
        
        {
          !this.state.askForFeedback && !this.state.done &&
          <div className='main'>
            <div className='helpful'>
              <div className='title'>Did you find this guide helpful?</div>
              <div className='bool-options'>
                <div className='option' onClick={() => { this.onBooleanClick(true) }}>Yes</div>
                <div className='option' onClick={() => { this.onBooleanClick(false) }}>No</div>
              </div> 
            </div>
          </div>  
          
        }

        {
          this.state.done &&
          <div className='title'>Thank you for your feedback!</div>
        }

        {
          this.state.askForFeedback &&
          <div>
            <div className='title'>Please provide any feedback you have for this article</div>
            <form>
              <div className='inputs'>
                <textarea ref={r => this.textRef = r} />
                <input ref={(r) => { this.emailref = r }} type='text' name='email' placeholder='Include your email address if you would like to hear back from us' />
              </div>  
              <Button onClick={this.onClick}>Send</Button>
            </form>
          </div>
        }
        
      </div>
    );  
  }
}
