import React from 'react';
import Link from "../Link"
import Image from '../Image';
import './IconLink.scss';


export default function IconLink ({
  to,
  title
}) {

  let iconSrc;

  if (to.indexOf('pdftron') > -1) {
    iconSrc = '/favicon-32x32.png';
  } else if (to.indexOf('github') > -1) {
    iconSrc = '/assets/images/icons/github.png';
  } else {
    iconSrc='/assets/images/icons/social-media-icon-url.svg'
  }

  return (
    <Link to={to} className='IconLink'>
      <Image src={iconSrc} style={{marginRight: '6px', maxHeight: '16px'}} />
      <p>{title}</p>
    </Link>
  )
}