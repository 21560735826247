import React, { PureComponent } from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import SideNav from '../../components/SideNav';
import TableOfContents from '../../components/TableOfContents';
import HTMLToReact from '../../components/HTMLToReact';
import Feedback from '../../components/Feedback';
import FooterNavLinks from '../../components/FooterNavLinks';
import Link from '../../components/Link';
import {
  getPathInfo,
  formatLinkNoTrialingSlash,
  makeConsistentURL,
} from '../../services/URL';
import {
  getPlatformSpellingForTitle,
  processUserFacingString,
} from '../../../processing/text';
import { setListeners, removeListeners } from '../../services/DOMService';
import {
  pushLastPage,
  setPreferred,
  formatTitle,
} from '../../services/DocumentationService';
import './DocumentationTemplate.scss';
import './DocumentationTemplate.mobile.scss';
import './DocumentationTemplate.print.scss';
import TabNames from '../../constants/tab-names.json';
import Tabs from '../../components/Tabs';
import { fireScrollDownloadEvent } from '../../services/Analytics';
import { getPlatformLanguages } from '../../services/LanguageService';
import { addQueryParams } from '../../services/URL';
import PlatformContext  from '../../context/PlatformContext';

class DocumentationTemplate extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      toc: [],
      lastClickedTab: null,
    };
    this.previousContext = {};
  }

  componentDidMount() {
    setListeners(`.HTMLToReact h2:not(.unselected-platform *), .HTMLToReact h3:not(.unselected-platform *)`, 'headerListener');
    const { pageContext, data } = this.props;

    const frontmatter = this.getFrontmatter();

    if (pageContext.slug && data.markdownRemark && frontmatter.title) {
      pushLastPage(pageContext.slug, frontmatter.title);
    }

    if (frontmatter.downloadEvent) {
      window.addEventListener('scroll', this.onScroll);
    }

    this.setState({ toc: this.getToc()});
    this.previousContext = this.context;
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
    removeListeners('headerListener');
  }

  componentDidUpdate() {
    if (this.previousContext.lastClickedTab !== this.context.lastClickedTab) {
      removeListeners('headerListener');
      setListeners(`.HTMLToReact h2:not(.unselected-platform *), .HTMLToReact h3:not(.unselected-platform *)`, 'headerListener');
      this.setState({ toc: this.getToc() });
    }
    this.previousContext = this.context;
  }

  getToc() {
    const query = document.querySelectorAll(
      ".HTMLToReact h2:not(.unselected-platform *), .HTMLToReact h3:not(.unselected-platform *)"
    );
    const toc = Array.from(query).map((item) => ({
      value: item.attributes.getNamedItem("pdftron-tag")?.nodeValue || "",
      depth: item.tagName === "H2" ? 2 : 3,
    }));
    return toc;
  }


  onScroll = () => {
    const body = document.body,
      html = document.documentElement;

    const frontmatter = this.getFrontmatter();

    const height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    const scrollTop = window.scrollY;

    const percent = scrollTop / height;

    if (percent > 0.4) {
      const pathInfo = getPathInfo(this.props.location.pathname);

      const e = frontmatter.downloadEvent;
      fireScrollDownloadEvent(e, pathInfo.platform);

      window.removeEventListener('scroll', this.onScroll);
    }
  };

  getTitle = (frontmatter) => {
    return formatTitle(frontmatter.metaTitle || frontmatter.title);
  };

  getFrontmatter = () => {
    let { pageContext = {}, data } = this.props;
    const { markdownRemark } = data;
    const mergeAll = (...args) =>
      args.reduce((a, b) => {
        for (let i in b) a[i] = b[i] || a[i];
        return a;
      }, {});

    return mergeAll(
      { ...markdownRemark.frontmatter },
      // pageContext must be last in the list to override
      // language frontmatter in shared guides (to show
      // the correct code snippet and hide irrelevant ones)
      { ...pageContext.originalFrontmatter }
    );
  };


  render() {
    let {
      location,
      data,
      pageContext = {},
      toggleLicenseModal,
      languages,
    } = this.props;
    const { markdownRemark } = data;

    const frontmatter = this.getFrontmatter();

    const extraMeta = pageContext.metadata || {};

    const { title: topTitle, description: topDescription } = extraMeta;

    const {
      blog,
      platforms,
      noTOC: noTOCFrontMatter,
      flex,
      metaDescription,
      otherLinks,
    } = frontmatter;

    let html = markdownRemark.html;

    const pathInfo = getPathInfo(this.props.location.pathname);

    if (frontmatter.languages) {
      languages = frontmatter.languages.split(' ');
    }

    languages = getPlatformLanguages(pathInfo.platform, languages);
    const noTOC = noTOCFrontMatter;

    let linkList = [];
    let selectedOption = null;

    if (flex) {
      const split = flex.split(' ');
      const originalPath = formatLinkNoTrialingSlash(this.props.location.pathname);
      const splitPath = originalPath.split('/');
      selectedOption = splitPath[splitPath.length - 1];
      let basePath;

      if (split.indexOf(selectedOption) === -1) {
        basePath = splitPath.join('/');
        selectedOption = split[0];
      } else {
        basePath = splitPath.slice(0, -1).join('/');
      }
      split.forEach((s) => {
        linkList.push({
          url: addQueryParams(`${basePath}/${s}`),
          basePath,
          suffix: s,
        });
      });
    }

    let canonical;

    if (
      frontmatter.canonical &&
      frontmatter.canonical.indexOf('shared') === -1
    ) {
      canonical = frontmatter.canonical;
    } else if (
      pageContext.canonical &&
      pageContext.canonical.indexOf('shared') === -1
    ) {
      canonical = pageContext.canonical;
    } else if (
      pageContext.metaSlug &&
      pageContext.metaSlug.indexOf('shared') === -1
    ) {
      canonical = pageContext.metaSlug;
    } else {
      canonical = location.pathname;
    }

    const sideNavs =
      this.props.data.allSideNavYaml.edges.map((e) => e.node) || [];
    const bottomTitle = frontmatter.title || markdownRemark.frontmatter.title;

    const { platform } = pathInfo;
    return (
      <React.Fragment>
        <Helmet>
          <title>
            {processUserFacingString(this.getTitle(frontmatter), {
              platform: platform,
              useReplacementLabels: true,
            })}
          </title>
          {metaDescription && (
            <meta
              name="description"
              content={processUserFacingString(metaDescription, {
                platform: platform,
                useReplacementLabels: true,
              })}
            />
          )}

          {canonical && (
            <link
              rel="canonical"
              href={makeConsistentURL(`https://docs.apryse.com${canonical}`)}
            />
          )}
        </Helmet>

        <div className="doc-content-wrapper">
          <SideNav
            sideNavs={sideNavs}
            pathname={location.pathname}
            platform={platform}
          />

          <div
            className={`DocumentationTemplate ${
              sideNavs.length ? 'shrink' : ''
            } `}
          >
            <TableOfContents
              key={location.pathname + location.search}
              location={location}
              Mobile
              Tablet
              noTOC={noTOC}
              toc={this.state.toc}
            />
            {topTitle && (
              <h2
                className="topTitle"
                dangerouslySetInnerHTML={{
                  __html: processUserFacingString(topTitle, {
                    platform: platform,
                    useReplacementLabels: true,
                  }),
                }}
              />
            )}

            {topDescription && (
              <p
                className="topDescription"
                dangerouslySetInnerHTML={{
                  __html: processUserFacingString(topDescription, {
                    platform: platform,
                    useReplacementLabels: true,
                  }),
                }}
              />
            )}

            {linkList.length > 0 && (
              <Tabs
                className="doc-tabs"
                selected={selectedOption}
                data={linkList.map((l) => {
                  const { url, suffix } = l;
                  const selected = selectedOption === suffix;
                  return {
                    label:
                      TabNames[suffix] || getPlatformSpellingForTitle(suffix),
                    to: url,
                    key: suffix,
                    selected,
                  };
                })}
                onClick={(suffix) => {
                  setPreferred({ platform: platform, tab: suffix });
                }}
                autoWidth
                cache
              />
            )}

            {bottomTitle && (
              <h1 className={`title ${topTitle ? 'hasTopTitle' : ''}`}>
                {processUserFacingString(bottomTitle, {
                  platform: platform,
                  useReplacementLabels: true,
                })}
              </h1>
            )}

            <HTMLToReact
              html={html}
              pathname={location.pathname}
              location={location}
              toggleLicenseModal={toggleLicenseModal}
              languages={languages}
            />

            <p className="support">
              <b>Trial setup questions?</b>{' '}
              <Link newTab to="https://apryse.com/discord?docLink=docDis">
                Ask experts on Discord
              </Link>
              <br />
              Need other help?{' '}
              <Link newTab to="https://support.apryse.com/?docLink=docSup">
                Contact Support
              </Link>
              <br />
              Pricing or product questions?{' '}
              <Link newTab to="https://apryse.com/form/contact-sales?docLink=docSal">
                Contact Sales
              </Link>
            </p>

            <FooterNavLinks platform={platform} />

            <Feedback pathname={location.pathname} />
          </div>

          <TableOfContents
            key={location.pathname + location.search}
            noTOC={noTOC}
            platforms={platforms}
            platform={platform}
            blog={blog}
            location={location}
            Desktop
            otherLinks={otherLinks}
            shrinkToFit
            toc={this.state.toc}
          />
              </div>
            </React.Fragment>
          )
        }
}
DocumentationTemplate.contextType = PlatformContext;
export default DocumentationTemplate;

export const query = graphql`
  query DocumentationTemplate($slug: String!, $sideNav: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        languages
        schema
        blog
        platforms
        metaTitle
        metaDescription
        noTOC
        flex
        showBack
        canonical
        downloadEvent
        otherLinks
      }
    }

    allSideNavYaml(filter: { slug: { eq: $sideNav } }) {
      edges {
        node {
          id
          slug
          group
          header
          chunk
          title
          link
          android
          ios
          web
          uwp
          xamarin
          linux
          windows
          mac
          core
          cli
          dotnet
          dotnetcore
          nodejs
          java
          cpp
          php
          python
          ruby
          go
          autoexpand
          subheader
          rightPanel
          lastHeader
          links {
            title
            link
            android
            ios
            web
            uwp
            xamarin
            linux
            windows
            mac
            core
            cli
            dotnet
            dotnetcore
            nodejs
            java
            cpp
            php
            python
            ruby
            go
            chunk
          }
        }
      }
    }
  }
`;
