import ScrollIntoView from 'scroll-into-view';

export const smoothScrollToElement = async (ele, time = 350, offset=140) => {
  setTimeout(() => {
    ScrollIntoView(ele, {
      time,
      align: {
        topOffset: -(window.innerHeight / 2) + offset
      } 
    })
  }, 0)
  
}

export const loadWebviewer = (viewerElement, options = {}) => {

  const { version = '7.3.1' } = options;
  delete options.version;

  return new Promise(resolve => { 

    const mount = () => {
      window.WebViewer({
        l: 'demo:lbittner@pdftron.com:75cc9d3d018f73761d35d3bc1a49de2f84b78036f8f756655c',
        path: `/${version}/lib`,
        ...options
      }, viewerElement).then(instance => {
        resolve(instance);
      });

    }

    if (window.PDFTron || window.WebViewer) {
      mount();
    } else {
      const script = document.createElement('script');
      script.src = `/${version}/lib/webviewer.min.js`;
      script.onload =  mount;
      document.body.appendChild(script);
    }

  });

}


// TOC header listener stuff
const isServerRender = typeof window === 'undefined';
let listeners = []
const TRIGGER_POINT = isServerRender ? 600 : window.innerHeight * 0.35;
const THRESHHOLD = 50;
const DEBUG = false;

const eventListeners = {};
let lastActive = '';
let lastScrollTop = 0;


// This function checks what section in the documentation is active
// It uses header nodes to determine what section is in the top half of the viewport
const scrollHandler = (selector) => {
  // Gets all the h2 and h3 nodes in the documenation
  const headers = document.querySelectorAll(selector) || [];

  // We need to determine direction of scroll because the logic is different based on direction
  const newScrollTop = isServerRender ? 0 : window.scrollY;
  const direction = newScrollTop > lastScrollTop ? 'down' : 'up';
  lastScrollTop = newScrollTop;

  for (let i = 0; i < headers.length; i++) {
    const header = headers[i];

    // Get the y position of the header (relative to the viewport)
    const top = header.getBoundingClientRect().top;
    
    // If we're scrolling down and the top of the header is ABOVE the threshold (meaning its in the top 40% of the screen)
    // then we fall into this logic
    if (direction === 'down' && top < TRIGGER_POINT) {
      
      // Get the position of the next header. This lets us determine the height of the entire section
      const nextHeader = headers[i === headers.length - 1 ? i : i + 1]
      const nextTop = nextHeader.getBoundingClientRect().top;

      // If the next header is anywhere below the trigger point (bottom 60% of the screen), then we can mark this header as active.
      // So basically if the content between the current header and the next header is touching the trigger point,
      // set the header as active.
      if (nextTop > TRIGGER_POINT || nextHeader === header) {
        const id = header.getAttribute('pdftron-tag');
        if (lastActive === id) break;
        listeners.forEach(l => l(id));  
        break;
      }     
    }

    // If we are scrolling up, and the header is BELOW the trigger point + 2x the threshhold (so in the bottom 50%ish of the screen),
    // Then we mark the previous header as active because most of its content is in the top half of the screen.
    if (direction === 'up' && top > TRIGGER_POINT + (THRESHHOLD*2)) {
      const lastHeader = headers[i === 0 ? i : i - 1];
      const id = lastHeader.getAttribute('pdftron-tag');
      if (lastActive === id) break;
      listeners.forEach(l => l(id));
      break;
    }

  }
}

// This stuff just adds / removes listeners and fires the function above
// Used for tracking what the active section in the documentation is.

export const setListeners = (selector, key) => {
  const r = () => scrollHandler(selector);
  eventListeners[key] = r;
  removeListeners(key);
  document.addEventListener('scroll', eventListeners[key]);

  if (DEBUG) {
  
    const node = document.createElement('div');
    node.style.position = 'fixed';
    node.style.zIndex = '9999999';
    node.style.height = '1px';
    node.style.backgroundColor = 'red';
    node.style.width = '100vw';
    node.className = 'MARKER';
    node.style.top = TRIGGER_POINT + 'px';
    document.body.appendChild(node);
  
  }
}

export const removeListeners = (key) => {
  document.removeEventListener('scroll', eventListeners[key]);
}

export const onHeaderChanged = (funcRef) => {
  listeners.push(funcRef);
}

export const offHeaderChanged = (funcRef) => {
  listeners = listeners.filter(l => l !== funcRef);
}
